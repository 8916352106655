/* dialog pages */
export const RSY_PAGE_BEARING_TYPE = 'RSY_PAGE_BEARING_TYPE';
export const RSY_PAGE_BEARING = 'RSY_PAGE_BEARING';
export const PROPERTY_PAGE_MOUNTING = 'PROPERTY_PAGE_MOUNTING';
export const PAGE_MOUNTING_MANAGER_SEAT = 'PAGE_MOUNTING_MANAGER_SEAT';
export const PAGE_MOUNTING_MANAGER_MEASURING_MOUTING_METHODS =
  'PAGE_MOUNTING_MANAGER_MEASURING_MOUTING_METHODS';
export const PROPERTY_PAGE_MOUNTING_SITUATION =
  'PROPERTY_PAGE_MOUNTING_SITUATION';
export const PROPERTY_PAGE_MOUNTING_SITUATION_SUB =
  'PROPERTY_PAGE_MOUNTING_SITUATION_SUB';
export const PAGE_RESULT = 'PAGE_RESULT';

/* dialog members */
export const RSY_BEARING_TYPE = 'RSY_BEARING_TYPE';
export const RSY_BEARING_SERIES = 'RSY_BEARING_SERIES';
export const RSY_BEARING = 'RSY_BEARING';
export const IDCO_DESIGNATION = 'IDCO_DESIGNATION';
export const IDMM_SHAFT_MATERIAL = 'IDMM_SHAFT_MATERIAL';
export const IDMM_BEARING_SEAT = 'IDMM_BEARING_SEAT';
export const IDMM_HYDRAULIC_NUT_TYPE = 'IDMM_HYDRAULIC_NUT_TYPE';
export const IDMM_INNER_RING_EXPANSION = 'IDMM_INNER_RING_EXPANSION';
export const IDMM_RADIAL_CLEARANCE_REDUCTION =
  'IDMM_RADIAL_CLEARANCE_REDUCTION';
export const IDMM_MODULUS_OF_ELASTICITY = 'IDMM_MODULUS_OF_ELASTICITY';
export const IDMM_POISSON_RATIO = 'IDMM_POISSON_RATIO';
export const IDMM_MEASSURING_METHOD = 'IDMM_MEASSURING_METHOD';
export const IDMM_MOUNTING_METHOD = 'IDMM_MOUNTING_METHOD';
export const IDMM_NUMBER_OF_PREVIOUS_MOUNTINGS =
  'IDMM_NUMBER_OF_PREVIOUS_MOUNTINGS';
export const IDMM_CLEARANCE_REDUCTION_INPUT = 'IDMM_CLEARANCE_REDUCTION_INPUT';
export const IDMM_INNER_SHAFT_DIAMETER = 'IDMM_INNER_SHAFT_DIAMETER';

/* member types */
export enum MemberTypes {
  LazyList = 'lazy-list',
  Boolean = 'boolean',
  List = 'list',
  RefList = 'ref-list',
  Number = 'number',
}

/* runtime tables */
export const TBL_BEARING_PREFLIGHT = 'TBL_BEARING_PREFLIGHT';
export const TBL_SHAFT_MATERIAL = 'TBL_SHAFT_MATERIAL';

/* members by page */
export const bearingMembers: string[] = [
  RSY_BEARING_TYPE,
  RSY_BEARING_SERIES,
  RSY_BEARING,
];
export const bearingSeatMembers: string[] = [IDMM_BEARING_SEAT];
export const measuringAndMountingMembers: string[] = [
  IDMM_MEASSURING_METHOD,
  IDMM_MOUNTING_METHOD,
];
export const calculationOptionsMembers: string[] = [
  IDMM_HYDRAULIC_NUT_TYPE,
  IDMM_NUMBER_OF_PREVIOUS_MOUNTINGS,
  IDMM_CLEARANCE_REDUCTION_INPUT,
  IDMM_RADIAL_CLEARANCE_REDUCTION,
  IDMM_INNER_SHAFT_DIAMETER,
  IDMM_SHAFT_MATERIAL,
];
